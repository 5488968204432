import React from 'react';
import styled from 'styled-components';
import { Sliders } from 'react-feather';

const CancelAndComparToolsButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CancelButton = styled.button`
  display: flex;
  color: #f00;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
`;

const CompareButton = styled.button`
  display: flex;
  flex-grow: 1;
  min-width: 0;
  max-width: fit-content;
  font-family: Univers;
  font-size: 14px;
  background-color: ${(props) =>
    props.$toolComparison ? '#4EAD5B' : 'var(--color-gray3)'};
  color: white;
  border: unset;
  padding: 5px;
  transition: color 0.2s ease-out, background-color 0.2s ease-out,
    border-bottom-color 0.2s ease-out;
  cursor: ${(props) => (props.$isSelectionMade ? 'pointer' : '')};
  &:hover {
    cursor: pointer;
    background-color: var(--color-gray1);
  }
`;

const ButtonText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledSlider = styled(Sliders)`
  display: inline-block;
  cursor: pointer;
  margin-right: 8px;
  margin-left: 2px;
  padding-top: 2px;

  @media (max-width: 900px) {
    margin-right: 0;
  }
`;

const CompareToolsButton = ({
  tools,
  toolComparison,
  enableToolComparison,
  setComparisonArr,
  canCompare,
}) => {
  const navigateToToolComparison = () => {
    const toolsURI = encodeURIComponent(tools.toString());

    if (!toolComparison) {
      enableToolComparison(true);
    } else if (canCompare) {
      navigate('/search/tool-comparison?tools=' + toolsURI);
    }
  };

  return (
    <CancelAndComparToolsButtonContainer>
      {toolComparison && (
        <CancelButton
          onClick={() => {
            enableToolComparison(false);
            setComparisonArr([]);
          }}
        >
          Cancel
        </CancelButton>
      )}
      <CompareButton
        $toolComparison={toolComparison}
        disabled={!canCompare}
        style={{
          opacity: canCompare ? 1 : 0.5,
          pointerEvents: canCompare ? 'auto' : 'none',
          //background: canCompare ? '' : '#4EAD5B',
        }}
        onClick={() => navigateToToolComparison()}
      >
        <StyledSlider size={16} strokeWidth={1.5} />
        <ButtonText>COMPARE TOOLS</ButtonText>
      </CompareButton>
    </CancelAndComparToolsButtonContainer>
  );
};

export default CompareToolsButton;
