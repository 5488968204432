import React, { useEffect, useState, useRef, useContext } from 'react';
import { useQuery } from 'react-query';
import { useOktaAuth } from '@okta/okta-react';
import { appInsights } from '../../appInsights.js';
import DOMPurify from 'dompurify';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import { ErrorModalContext } from '../../Modals/ErrorModal';
import ApplicationContext from '../../../js/ApplicationContext.js';
import ErrorModal from '../../Modals/ErrorModal.jsx';

import PageTitle from '../../shared/PageTitle';
import ToolDescriptionSummary from './ToolDescriptionSummary.jsx';
import ToolDescriptionExpanded from './ToolDescriptionExpanded';
import UpdateItemInCartForm from './UpdateItemInCartForm.jsx';
import FavoritesToggleButton from '../Favorites/FavoritesToggleButton.jsx';
import Breadcrumb from '../../shared/Breadcrumb.jsx';

import { fetchFavorites } from '../../backend/favorites.js';
import { fetchTool } from '../../backend/tooldetails.js';
import IconView from '../../ModelPreview/IconView.jsx';

import * as siteConfig from '../../siteconfig';
import ToolDocuments from './ToolDocuments.jsx';

const MainContainer = styled.div`
  min-height: calc(100vh - 300px);
`;

const StyledContainer = styled.div`
  background-color: white;
`;

const ToolDetailsContainer = styled.div`
  padding: 28px 96px 0;
  @media (max-width: 900px) {
    padding: 18px 20px 0 20px;
  }
  position: relative;
`;

const ToggleDetailsButton = styled.button`
  width: 142px;
  height: 55px;
  font-family: Univers;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-bottom: 1.25px solid black;
  margin-top: 50px;
  margin-bottom: 55px;
  color: ${(props) => (props.$isExpanded ? 'var(--color-gray1)' : 'white')};
  background-color: ${(props) =>
    props.$isExpanded ? 'var(--color-gray4)' : 'var(--color-primary)'};

  @media (max-width: 900px) {
    margin-top: 0px;
    margin-bottom: 30px;
  }

  // @media (max-width: 1024px) {
  //   border-radius: 10px;
  // }
`;

const SkeletonLoaderTitle = styled(Skeleton)`
  margin-bottom: 26px;
  margin-top: 26px;
`;

const SkeletonLoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SkeltonContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  width: 250px;
`;

const SkeltonContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
`;

const SkeletonModel = styled.div`
  width: 30%;
  margin-left: 20px;
  text-align: end;
`;

const SkeletonRow = styled(Skeleton)`
  margin-bottom: 10px;
`;

const SkeletonBlock = styled(Skeleton)`
  margin-top: 20px;
  margin-bottom: 30px;
`;

const SkeletonRowMobile = styled(Skeleton)`
  margin-top: 15px;
`;

const SkeletonLoaderTitleMobile = styled(Skeleton)`
  margin-top: 30px;
  margin-bottom: 10px;
`;

const FavoritesAndCartContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: flex-start;

  @media (max-width: 1024px) {
    align-items: center;
  }
`;

const FavoritesToggleStyledButton = styled(FavoritesToggleButton)`
  width: 182px;
  padding: 0;

  @media (min-width: 1024px) {
    margin-top: 22px;
  }
  justify-content: center;
  float: right;
`;

const BreadcrumbContainer = styled.div`
  display: flex;
  margin: 18px 0 24px 24px;
`;

const SplideContainer = styled(Splide)`
  height: 42px;
  width: 90%;

  padding-left: 4%;
  padding-right: 4%;

  .splide__slide {
    width: calc(15%);
  }

  .splide__arrow {
    background: none;
    height: 20px;
    width: 20px;
  }

  .splide__arrow--prev {
    background: var(--color-primary);
    margin-right: 20px;

    @media (max-width: 400px) {
      right: 88%;
      left: 0;
    }
  }

  .splide__arrow--next {
    background: var(--color-primary);
    @media (max-width: 400px) {
      right: 0;
    }
  }

  .splide__arrow svg {
    fill: #fff;
    height: 0.8em;
    width: 0.8em;
  }
`;

const SlideContainer = styled.div`
  display: flex;
  width: 100%;
  height: 42px;
  align-items: center;

  @media (max-width: 400px) {
    justify-content: center;
  }
`;

const SlideLinkedButton = styled.div`
  background: none;
  border: none;
  height: 100px;
  display: flex;
  max-width: 90%;
  min-width: 100px;
  align-items: center;
  font-family: Univers;
  font-size: 14px;
  text-align: left;
  color: var(--color-primary);
  cursor: pointer;

  @media (max-width: 400px) {
    max-width: 90%;
  }
`;

const ButtonText = styled.div`
  color: var(--color-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
`;

const ToolDetailsPage = () => {
  const navigate = useNavigate();

  const { isShoppingCartLoading } = useContext(ApplicationContext);

  const { id } = useParams();
  const { authState } = useOktaAuth();

  const [classType, setClassType] = useState('');
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isReadyToFetch, setIsReadyToFetch] = useState(false);

  const [hasSearchHistoryFlag, setHasSearchHistoryFlag] = useState(false);
  const [, setError] = useContext(ErrorModalContext);

  const [iconViewShow, seticonViewShow] = useState(true);

  const location = useLocation();

  useEffect(() => {
    const trackViewChange = () => {
      appInsights.trackPageView({ name: location.pathname });
    };
    trackViewChange();
  }, [location.pathname]);

  const { data, isLoading, error } = useQuery('tool', () => fetchTool(id), {
    onError: (error) => {
      const errorCode = error.message.split('/');
      setError(
        error.constructor.name === 'TypeError'
          ? {
              title: 'Looks like something went wrong',
              message:
                'Network connection lost. Please check your network connection and try again.',
            }
          : {
              title: 'Looks like something went wrong',
              message:
                'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
              errorCode: `#TO-1-${errorCode[1]}`,
            }
      );
    },
  });

  const { data: favoritesData, refetch } = useQuery(
    'fetchFavorites',
    fetchFavorites,
    {
      onError: (error) => {
        const errorCode = error.message.split('/');
        if (error.constructor.name === 'TypeError') {
          setError({
            title: 'Looks like something went wrong',
            message:
              'Network connection lost. Please check your network connection and try again.',
          });
        } else if (errorCode[1] !== '401') {
          setError({
            title: 'Looks like something went wrong',
            message:
              'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
            errorCode: `#FA-1-${errorCode[1]}`,
          });
        }
      },
    }
  );

  useEffect(() => {
    if (data?.SapNumber && !suggestedItems.includes(data.SapNumber)) {
      const productObject = {
        materialNumber: data.SapNumber,
        name: data.Name,
        productPropertyData: data.DescriptionLite.slice(0, 3),
      };

      if (
        suggestedItems.length === 0 ||
        !suggestedItems.some(
          (product) => product.materialNumber === data.SapNumber
        )
      ) {
        if (suggestedItems.length >= 3) {
          suggestedItems.push(productObject);
          suggestedItems.shift();
          localStorage.setItem(
            'suggestedItems',
            JSON.stringify(suggestedItems)
          );
        } else {
          suggestedItems?.push(productObject);
          localStorage.setItem(
            'suggestedItems',
            JSON.stringify(suggestedItems)
          );
        }
      }
    }
  }, [data]);

  useEffect(() => {
    setHasSearchHistoryFlag(
      sessionStorage.getItem('hasSearchHistory') === 'true'
    );
    sessionStorage.setItem('hasSearchHistory', 'false');
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsReadyToFetch(true);
    }, 2500);
    return () => clearTimeout(timeoutId);
  }, []);

  const handleResize = () => {
    const width = window.innerWidth;
    setScreenWidth(width);
    if (width < 900) {
      setClassType('compact');
    } else {
      setClassType('');
    }
  };

  useEffect(() => {
    setIsPageLoaded(true);
    window?.addEventListener('resize', handleResize);

    return () => {
      window?.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isPageLoaded) {
      if (screenWidth < 900) {
        setClassType('compact');
      } else {
        setClassType('');
      }
    }
  }, [screenWidth, isPageLoaded]);

  const handleBackToSearchResults = () => {
    const sanitizedId = DOMPurify.sanitize(id);
    if (window.history.state.idx === 0 || !hasSearchHistoryFlag) {
      sessionStorage.setItem('hasSearchHistoryFlag', 'false');
      navigate(`/search?term=${sanitizedId}`);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    setHasSearchHistoryFlag(
      sessionStorage.getItem('hasSearchHistory') === 'true'
    );
    sessionStorage.setItem('hasSearchHistory', 'false');
  }, []);

  useEffect(() => {
    const handlePopState = (event) => {
      handleBackToSearchResults();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [hasSearchHistoryFlag, id, navigate]);

  const scrollableContentRef = useRef(null);

  useEffect(() => {
    if (scrollableContentRef.current && isExpanded) {
      scrollableContentRef.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isExpanded]);

  const onShowMoreButtonClick = () => {
    setIsExpanded((prev) => !prev);
  };

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    document.title = `${data?.Name || 'Tool Details'}`;

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content =
      'Tool details page within eCompletions search tool catalog';

    document.head.appendChild(metaDescription);

    return () => {
      document.title = '';
      document.head.removeChild(metaDescription);
    };
  }, [data]);

  const viewedProductsHash = localStorage.getItem('currentHash');
  let suggestedItems = [];
  if (viewedProductsHash === COMMITHASH) {
    suggestedItems = JSON.parse(localStorage.getItem(`suggestedItems`)) || [];
  } else {
    localStorage.setItem('currentHash', COMMITHASH);
    localStorage.setItem('suggestedItems', JSON.stringify([]));
  }

  const splideOptions = {
    type: false,
    heightRatio: 0.5,
    arrows: true,
    perPage: screenWidth < 400 ? 1 : 'auto',
    pagination: false,
  };

  const handleRedirectToBrowse = (clickedClassPath) => {
    const clickedIndex = data?.ClassPath.split('|').indexOf(clickedClassPath);

    if (clickedIndex === -1) {
      return;
    }

    const elementsBeforeClicked = data?.ClassPath.split('|').slice(
      0,
      clickedIndex + 1
    );

    const newUrl = `/browse?${elementsBeforeClicked.join('&')}`;

    navigate(newUrl);
  };

  const iconUrl = `${siteConfig.searchApiUrl}/tools/class/${data?.ClassId}/sap/${data?.SapNumber}`;
  const pdfUrl = data?.Properties?.filter(
    (feature) => feature?.DisplayName === 'BDMI'
  )[0]?.Value;

  return (
    <MainContainer>
      <ErrorModal />
      <StyledContainer>
        <ToolDetailsContainer>
          {isLoading || !isReadyToFetch || isShoppingCartLoading ? (
            <>
              {classType === 'compact' ? (
                <>
                  <SkeletonLoaderTitleMobile width={'90%'} height={45} />

                  <SkeletonRowMobile height={27} width={'30%'} />
                  <SkeletonRowMobile height={27} width={'13%'} />
                  <SkeletonRowMobile height={27} width={'27%'} />
                  <SkeletonRowMobile height={27} width={'77%'} />
                  <SkeletonRowMobile height={27} width={'57%'} />
                  <SkeletonRowMobile height={27} width={'23%'} />
                  <SkeletonRowMobile height={27} width={'73%'} />
                  <SkeletonRowMobile height={27} width={'57%'} />
                  <SkeletonRowMobile height={27} width={'38%'} />
                  <SkeletonRowMobile height={27} width={'23%'} />
                  <SkeletonRowMobile height={27} width={'47%'} />
                  <SkeletonRowMobile height={27} width={'57%'} />
                  <SkeletonRowMobile height={27} width={'13%'} />
                </>
              ) : (
                <>
                  <SkeletonLoaderTitle width={'70%'} height={55} />
                  <SkeletonLoaderContainer>
                    <SkeltonContainerLeft>
                      <SkeletonRow height={20} width={'30%'} />
                      <SkeletonRow height={20} width={'23%'} />
                      <SkeletonRow height={20} width={'47%'} />
                      <SkeletonRow height={20} width={'77%'} />
                      <SkeletonRow height={20} width={'57%'} />
                      <SkeletonRow height={20} width={'13%'} />
                      <SkeletonRow height={20} width={'18%'} />
                      <SkeletonRow height={20} width={'57%'} />
                      <SkeletonRow height={20} width={'38%'} />
                      <SkeletonRow height={20} width={'53%'} />
                      <SkeletonRow height={20} width={'77%'} />
                      <SkeletonRow height={20} width={'57%'} />
                      <SkeletonRow height={20} width={'100%'} />
                    </SkeltonContainerLeft>
                    <SkeltonContainerRight>
                      {' '}
                      <SkeletonRow height={20} width={'20%'} />
                      <SkeletonRow height={20} width={'73%'} />
                      <SkeletonRow height={20} width={'60%'} />
                      <SkeletonRow height={20} width={'43%'} />
                      <SkeletonRow height={20} width={'57%'} />
                      <SkeletonRow height={20} width={'20%'} />
                      <SkeletonRow height={20} width={'63%'} />
                      <SkeletonRow height={20} width={'43%'} />
                      <SkeletonRow height={20} width={'23%'} />
                      <SkeletonRow height={20} width={'77%'} />
                      <SkeletonRow height={20} width={'43%'} />
                      <SkeletonRow height={20} width={'100%'} />
                      <SkeletonRow height={20} width={'10%'} />
                    </SkeltonContainerRight>
                    <SkeletonModel>
                      <SkeletonRow height={600} width={'60%'} />
                    </SkeletonModel>
                  </SkeletonLoaderContainer>

                  <SkeletonBlock width={'80%'} height={335} />
                </>
              )}
            </>
          ) : (
            <>
              {error || Object.entries(data)?.length === 0 ? (
                <>
                  <PageTitle text={'Tool not found'} />
                  <p>
                    {`A tool with a SAP Number or Legacy Number of '${id}' could
                    not be found.`}
                  </p>
                </>
              ) : (
                <>
                  {classType !== 'compact' ? (
                    <>
                      {authState?.isAuthenticated && (
                        <FavoritesAndCartContainer>
                          <div>
                            <UpdateItemInCartForm
                              toolData={data}
                              oktaRedirectOnLoginTo={`/tool/${data?.SapNumber}`}
                            />
                            <FavoritesToggleStyledButton
                              pagePath={location.pathname}
                              sapNumber={data?.SapNumber}
                              isFavorited={favoritesData?.some(
                                (favoritedItem) =>
                                  favoritedItem.SapNumber ===
                                  data?.SapNumber.toString()
                              )}
                              refetchFavoritesData={refetch}
                            />
                          </div>
                        </FavoritesAndCartContainer>
                      )}
                      <PageTitle
                        text={
                          authState?.isAuthenticated
                            ? data?.Name || 'Tool Name N/A'
                            : data.ProductDescription
                        }
                      />
                      {!authState?.isAuthenticated && (
                        <hr
                          style={{
                            backgroundColor: 'var(--color-gray3)',
                            border: 'none',
                            height: '1px',
                          }}
                        />
                      )}
                      {screenWidth > 400 && authState?.isAuthenticated && (
                        <BreadcrumbContainer>
                          {data?.ClassPath?.split('|')
                            .slice(2)
                            .map((path, index) => (
                              <Breadcrumb
                                key={index}
                                text={path}
                                lastOfType={
                                  index ===
                                  data?.ClassPath.split('|').slice(2).length - 1
                                }
                                onClick={() => handleRedirectToBrowse(path)}
                              />
                            ))}
                        </BreadcrumbContainer>
                      )}
                      {!authState?.isAuthenticated && !isLoading ? (
                        <p>
                          Please login to access additional tool information.
                        </p>
                      ) : (
                        <>
                          <ToolDescriptionSummary
                            toolDescription={data}
                            classType={classType}
                            isExpanded={isExpanded}
                            onShowMoreButtonClick={onShowMoreButtonClick}
                          />
                        </>
                      )}{' '}
                    </>
                  ) : (
                    <>
                      {authState?.isAuthenticated && (
                        <FavoritesAndCartContainer>
                          <FavoritesToggleStyledButton
                            pagePath={location.pathname}
                            sapNumber={data?.SapNumber}
                            isFavorited={favoritesData?.some(
                              (favoritedItem) =>
                                favoritedItem.SapNumber ===
                                data?.SapNumber.toString()
                            )}
                            refetchFavoritesData={refetch}
                          />
                        </FavoritesAndCartContainer>
                      )}
                      <PageTitle
                        text={
                          authState?.isAuthenticated
                            ? data?.Name || 'Tool Name N/A'
                            : data.ProductDescription
                        }
                      />
                      {screenWidth < 400 && authState?.isAuthenticated && (
                        <SplideContainer options={splideOptions}>
                          {data?.ClassPath?.split('|')
                            .slice(2)
                            .map((path, index) => (
                              <SplideSlide key={index}>
                                <SlideContainer>
                                  <SlideLinkedButton
                                    onClick={
                                      index ===
                                      data?.ClassPath.split('|').slice(2)
                                        .length -
                                        1
                                        ? null
                                        : () => handleRedirectToBrowse(path)
                                    }
                                  >
                                    <ButtonText> {path} </ButtonText>
                                  </SlideLinkedButton>
                                </SlideContainer>
                              </SplideSlide>
                            ))}
                        </SplideContainer>
                      )}

                      <hr
                        style={{
                          backgroundColor: '#e5e9ed',
                          border: 'none',
                          height: '1px',
                        }}
                      />
                      {!authState?.isAuthenticated && !isLoading ? (
                        <p>
                          Please login to access additional tool information.
                        </p>
                      ) : (
                        <>
                          {iconViewShow && (
                            <IconView
                              iconUrl={iconUrl}
                              iconName={data.ProductDescription}
                              onIconNotFound={() => seticonViewShow(false)}
                            />
                          )}
                          <UpdateItemInCartForm
                            toolData={data}
                            oktaRedirectOnLoginTo={`/tool/${data?.SapNumber}`}
                            buttonWidth={'120px'}
                          />
                          <ToolDescriptionSummary
                            toolDescription={data}
                            classType={classType}
                            isExpanded={isExpanded}
                            onShowMoreButtonClick={onShowMoreButtonClick}
                          />
                          {classType === 'compact' && (
                            <>
                              <ToggleDetailsButton
                                $isExpanded={isExpanded}
                                onClick={onShowMoreButtonClick}
                              >
                                {isExpanded ? 'SHOW LESS' : 'SHOW MORE'}
                              </ToggleDetailsButton>
                              {isExpanded &&
                                data.Properties != null &&
                                data.Properties.length > 0 && (
                                  <ToolDescriptionExpanded
                                    ref={scrollableContentRef}
                                    toolDescription={data}
                                    classType={classType}
                                  />
                                )}
                              <ToolDocuments
                                isExpanded={isExpanded}
                                pdfUrl={pdfUrl}
                                documentsData={data?.Documents}
                              />
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </ToolDetailsContainer>
      </StyledContainer>
    </MainContainer>
  );
};

export default ToolDetailsPage;
