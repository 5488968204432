import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  font-family: Univers;
  background-color: var(--color-gray1);
  height: 150px;
  padding: 30px 112px 12px 112px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 820px) {
    padding-right: 0;
    padding-left: 0;
  }
  @media (max-width: 1200px) {
    height: 200px;
  }
  @media (max-width: 550px) {
    height: 275px;
  }
`;

const FooterLinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 400px) {
    padding-right: 2%;
    padding-left: 2%;
  }
`;

const FooterLink = styled.a`
  color: var(--color-gray5);
  padding-bottom: 10px;
  padding-right: 3px;
  cursor: pointer;
  font-weight: 100;
  font-size: 16px;
  text-decoration: none;
`;

const HalInfo = styled.p`
  color: #9ea6b5;
  font-size: 16px;
  font-weight: 200;
  text-align: center;
  margin: 12px;
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <FooterContainer>
      <FooterLinksContainer>
        <FooterLink
          href="https://www.halliburton.com/en/about-us/corporate-governance/terms-and-conditions"
          target="_blank"
        >
          Terms and conditions |
        </FooterLink>
        <FooterLink
          href="https://www.halliburton.com/en/about-us/corporate-governance/privacy-policy"
          target="_blank"
        >
          Privacy policy
        </FooterLink>
      </FooterLinksContainer>
      <HalInfo>
        This website is designed to assist users in searching for products and
        providing general information. While we make every effort to ensure the
        accuracy of the information presented, We recommend confirming details
        directly with us by contacting Completions@Halliburton.com. By using
        this website, you acknowledge your understanding of these limitations.
      </HalInfo>
      <HalInfo>2000-{currentYear} Halliburton all rights reserved</HalInfo>
    </FooterContainer>
  );
};

export default Footer;
