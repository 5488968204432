import React, { useState } from 'react';
import styled from 'styled-components';
import IconView from '../../ModelPreview/IconView.jsx';
import { camelize } from '../../reactutils.js';
import * as siteConfig from '../../siteconfig';
import ToolDocuments from './ToolDocuments.jsx';
import { ChevronUp, ChevronDown } from 'react-feather';

const ToolSummaryContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ToolPropsOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ToolPropsContainer = styled.div`
  display: block;
  padding: 15px 0px 17px 21px;
  // border-radius: 10px;
  margin: 0 16px 0 11px;
  border: solid 1px #d9d9d9;
  position: relative;
`;

const ToolSummaryRowMobile = styled.div`
  padding: 15px 0px;
  width: 100%;
  font-family: Univers;
`;

const FeatureNameAndValueRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-family: Univers;
  font-size: 14px;
`;

const FeatureNameMobile = styled.div`
  font-weight: 500;
`;

const ToolFeatureNameColumn = styled.div`
  width: 250px;
  text-align: left;
  margin-right: 13px;
  font-weight: bold;
  padding: 5px 0;
  font-size: 14px;
  color: --color-gray1;
`;

const ToolFeatureValueColumn = styled.div`
  text-align: left;
  padding: 5px 0;
  color: --color-gray1;
  font-size: 14px;
`;

const ShowMoreLessButton = styled.button`
  padding: 8px 12px;
  width: 126px;
  // border-radius: 10px;
  background-color: #c00;
  color: #fff;
  font-family: Univers;
  font-size: 12px;
  font-weight: 500;
  border: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transform: translateY(50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ToolDescriptionSummary = ({
  toolDescription,
  classType,
  isExpanded,
  onShowMoreButtonClick,
}) => {
  const iconUrl = `${siteConfig.searchApiUrl}/tools/class/${toolDescription?.ClassId}/sap/${toolDescription?.SapNumber}`;
  const [iconViewShow, seticonViewShow] = useState(true);
  const pdfUrl = toolDescription?.Properties?.filter(
    (feature) => feature?.DisplayName === 'BDMI'
  )[0]?.Value;

  return (
    <ToolSummaryContainer>
      {classType !== 'compact' ? (
        <>
          <ToolPropsOuterContainer>
            <ToolPropsContainer>
              <FeatureNameAndValueRow>
                <ToolFeatureNameColumn>Material Number</ToolFeatureNameColumn>
                <ToolFeatureValueColumn>
                  {toolDescription.SapNumber}
                </ToolFeatureValueColumn>
              </FeatureNameAndValueRow>

              {toolDescription?.DescriptionLite?.map((tool) => (
                <FeatureNameAndValueRow key={tool.DisplayName + 1}>
                  <ToolFeatureNameColumn>
                    {camelize(tool.DisplayName, ['OD', 'ID'])}
                  </ToolFeatureNameColumn>
                  <ToolFeatureValueColumn>
                    {tool.Value || 'N/A'}
                  </ToolFeatureValueColumn>
                </FeatureNameAndValueRow>
              ))}
              {isExpanded &&
                toolDescription?.Properties?.map(
                  (tool) =>
                    tool.Name !== 'BDMI' && (
                      <FeatureNameAndValueRow
                        key={tool.DisplayName + tool.Value}
                      >
                        <ToolFeatureNameColumn>
                          {camelize(tool.DisplayName, ['OD', 'ID'])}
                        </ToolFeatureNameColumn>
                        <ToolFeatureValueColumn>
                          {tool.Value || 'N/A'}
                        </ToolFeatureValueColumn>
                      </FeatureNameAndValueRow>
                    )
                )}
              {toolDescription?.Properties != null &&
              toolDescription?.Properties.length > 0 ? (
                <ShowMoreLessButton onClick={onShowMoreButtonClick}>
                  {isExpanded ? 'SHOW LESS' : 'SHOW MORE'}
                  {isExpanded ? (
                    <ChevronUp color="#fff" size={20} />
                  ) : (
                    <ChevronDown color="#fff" size={20} />
                  )}{' '}
                </ShowMoreLessButton>
              ) : null}
            </ToolPropsContainer>
            <ToolDocuments
              isExpanded={isExpanded}
              pdfUrl={pdfUrl}
              documentsData={toolDescription.Documents}
            />
          </ToolPropsOuterContainer>
        </>
      ) : (
        <>
          <ToolSummaryRowMobile>
            {' '}
            <FeatureNameMobile>MATERIAL NUMBER</FeatureNameMobile>
            <div>{toolDescription.SapNumber}</div>
          </ToolSummaryRowMobile>{' '}
          {toolDescription?.DescriptionLite?.map((tool, i) => (
            <ToolSummaryRowMobile key={`${i}_${tool.DisplayName}`}>
              {' '}
              <FeatureNameMobile>{tool.DisplayName}</FeatureNameMobile>
              <div>{tool.Value || 'N/A'}</div>
            </ToolSummaryRowMobile>
          ))}
        </>
      )}
      {iconViewShow && classType !== 'compact' && (
        <IconView
          iconUrl={iconUrl}
          iconName={toolDescription.ProductDescription}
          onIconNotFound={() => seticonViewShow(false)}
        />
      )}
    </ToolSummaryContainer>
  );
};

export default ToolDescriptionSummary;
